/**
 * @file Table.
 */

/**
 * Table enhancements.
 */
const table = () => {
	const tables = document.querySelectorAll('.v-table');
	if (!tables.length) {
		return;
	}

	tables.forEach((t) => {
		const headers = t.querySelectorAll('thead td, thead th');
		if (!headers.length) {
			return;
		}

		const headerText = [];
		headers.forEach((h) => {
			const loop = parseInt(h.getAttribute('colspan') || '1', 10);
			for (let i = 0; i < loop; i++) {
				headerText.push(h.textContent);
			}
		});

		const rows            = t.querySelectorAll('tbody tr');
		rows.forEach((r) => {
			const cells = r.querySelectorAll('td, th');
			cells.forEach((c, i) => {
				const loop           = parseInt(c.getAttribute('colspan') || '1', 10);

				if (loop === 1) {
					c.dataset.header = headerText[i] || '';
				}
				/* const appliedHeaders = [];
				for ( let x = 0; x < loop; x++ ) {
					appliedHeaders.push( headerText[ i + x ] || '' );
				}
				c.dataset.header = appliedHeaders
					.filter( ( text ) => text.length )
					.filter( ( text, index ) => appliedHeaders.indexOf( text ) === index )
					.join( ', ' ); */
			});
		});

		/* const headersAdjusted = [];
		headers.forEach( ( h ) => {
			const loop = h.getAttribute( 'colspan' );
			if ( loop ) {
				for ( let i = 0; i < parseInt( loop ); i++ ) {
					headersAdjusted.push( h );
				}
			} else {
				headersAdjusted.push( h );
			}
		} );
		const rows            = t.querySelectorAll( 'tbody tr' );
		rows.forEach( ( r ) => {
			const cells = r.querySelectorAll( 'td, th' );
			cells.forEach( ( c, i ) => c.dataset.header = headersAdjusted[ i ].textContent );
		} ); */
	});
};
export default table;
